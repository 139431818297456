/* global _ */

/** @ngInject */
function customTextareaController (
  $scope,
  visionService,
  FileManagerService,
  AIService
) {
  let resCounter = 0
  $scope.loading = false

  $scope.callback = selectedWord => {
    let modelValue = _.get($scope.model, $scope.options.key, '')
    if (modelValue && modelValue !== '') {
      modelValue += `\n${selectedWord}`
    } else {
      modelValue = selectedWord
    }
    _.set($scope.model, $scope.options.key, modelValue)
    if ($scope.to.onChange) {
      $scope.to.onChange(modelValue, $scope.options)
    }
  }
  $scope.openVisionDialog = async function openVisionDialog () {
    if (
      $scope.to.preVisionDialog &&
      typeof $scope.to.preVisionDialog === 'function'
    ) {
      $scope.to.preVisionDialog()
    }
    if (!$scope.to.disabled) {
      await visionService.openVisionDialog({
        callback: $scope.callback,
        returnWords: false,
        key: `${$scope.options.type}_${$scope.options.key}`
      })
    }
    if (
      $scope.to.postVisionDialog &&
      typeof $scope.to.postVisionDialog === 'function'
    ) {
      $scope.to.postVisionDialog()
    }
  }

  const getAnswerToValue = (textDelta, end) => {
    if (textDelta) {
      if (resCounter === 0) {
        $scope.model[$scope.options.key] = textDelta
      } else {
        $scope.model[$scope.options.key] += textDelta
      }
      resCounter++
    }
    // Check if completion completed
    if (end) {
      if ($scope.to.onChange) {
        $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
      }
      $scope.loading = false
      resCounter = 0
    }
  }
  $scope.openVisionAiDialog = async function openVisionAiDialog () {
    if ($scope.loading) {
      return
    }
    const base64 = await FileManagerService.openUploadMenu({}, true)
    if (!base64) {
      return
    }
    $scope.loading = true
    $scope.model[$scope.options.key] = ''
    const settings = AIService.getPromptSettings('vision')
    const options = {
      max_tokens: settings.maxTokens,
      temperature: settings.temperature
    }
    const completion = new AIService.Completion(
      [
        {
          role: 'system',
          content: [
            {
              type: 'text',
              text: settings.plainPrompt.replace(
                '<AVAILABLE_OPTIONS>',
                `AVAILABLE_OPTIONS: N/A`
              )
            }
          ]
        },
        {
          role: 'user',
          content: [
            { type: 'text', text: $scope.to.aiPrompt },
            {
              type: 'image_url',
              image_url: {
                url: base64
              }
            }
          ]
        }
      ],
      options,
      getAnswerToValue
    )
    completion.send()
    $scope.loading = true
  }
}

module.exports = customTextareaController
