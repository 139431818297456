/* global moment */
require('./timePicker.scss')
/** @ngInject */
function timePickerController ($scope, DateTimeFormatService) {
  $scope.isDisabled = $scope.to.disabled || false
  $scope.hasTime = $scope.options.type === 'dateTimePicker'
  const dateTimeFormats = DateTimeFormatService.getDateTimeFormatsForField()
  $scope.dateOpts = {
    enableTime: true,
    noCalendar: true,
    time_24hr: true,
    placeholder: $scope.to.placeholder || null,
    dateFormat: dateTimeFormats.time,
    time_24hr: dateTimeFormats.time?.endsWith('K') ? false : true,
    minuteIncrement: 1,
    minTime: $scope.to.minTime || null,
    maxTime: $scope.to.maxTime || null,
    defaultDate:
      $scope.model[$scope.options.key] || moment().format(dateTimeFormats.time)
  }
}

module.exports = timePickerController
