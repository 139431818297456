/* global angular, _ */
import { isNilOrEmpty } from 'app/helper'
const {
  NPT_OPERATIONS
} = require('root/../common/constants/npt-constants.json')
const {
  TRIGGER_TYPES
} = require('root/../common/constants/workflow-constants.json')
function processValidator () {
  return {
    restrict: 'E',
    scope: {
      isInitialize: '=',
      update: '=?',
      validatorHandler: '=?',
      tools: '=',
      certificates: '=',
      fields: '=',
      workflows: '=',
      processValidatorReflection: '=',
      workorderTemplates: '=',
      workflowTypes: '=',
      workflowParts: '='
    },
    template: require('./process-validator.html'),
    link: function (scope, element, attrs) {
      scope.element = element
      angular.element(element).addClass('process-validator')
    },
    /** @ngInject */
    controller: function (
      $scope,
      $rootScope,
      $translate,
      VariablesUtilsService,
      DeviceFamily,
      Station,
      Table
    ) {
      const STATES = {
        ERROR: 'error',
        WARNING: 'warning',
        INFORMATION: 'information'
      }

      const systemVariables = VariablesUtilsService.getVariables('Workflow')

      systemVariables.push({
        name: $translate.instant('COMMON.CURRENT_DATE'),
        type: 'date'
      })
      const fieldsById = _.keyBy($scope.fields, 'id')

      const toolsById = _.keyBy($scope.tools, 'id')

      const workflowByRecordId = _.keyBy($scope.workflows, 'id')

      const certificatesById = _.keyBy($scope.certificates, 'id')

      const workorderTemplatesById = _.keyBy($scope.workorderTemplates, 'id')

      const workflowPartsByNumber = _.keyBy($scope.workflowParts, 'number')
      const initProcessValidator = async function initProcessValidator () {
        $scope.allMessages = []
        $scope.iconData = {
          information: {
            name: 'icon-information-outline',
            color: '#55b7e6',
            number: 0
          },
          warning: { name: 'icon-alert', color: '#ff9800', number: 0 },
          error: { name: 'icon-cancel', color: '#f44336', number: 0 }
        }
        $scope.validatorData = {
          error: [],
          warning: [],
          information: []
        }
        $scope.validatorFilter = {
          error: true,
          warning: true,
          information: true
        }
        $scope.states = Object.keys($scope.iconData)
        $scope.dataStates = _.cloneDeep($scope.states).reverse()
        angular.element($scope.element).addClass('process-validator')
        $scope.isExpanded = false
        $scope.messageExists = false
        $scope.deviceFamilies = DeviceFamily.find({
          filter: { fields: { id: true, name: true } }
        }).$promise
        $scope.stations = Station.find({
          filter: { fields: { id: true, name: true } }
        }).$promise
        let tables = Table.find({
          filter: {
            deleted: true,
            fields: { id: true, name: true, columns: true, deletedAt: true }
          }
        }).$promise
        $scope.deviceFamilies = await $scope.deviceFamilies
        $scope.stations = await $scope.stations
        tables = await tables
        $scope.tableById = _.keyBy(tables, 'id')
        $scope.stationsById = _.keyBy($scope.stations, 'id')
        $scope.isInitialize()
      }

      initProcessValidator()

      $scope.updateValidator = function updateValidator (workflow) {
        try {
          resetErrors()
          checkWorkflow(
            _.omit(workflow, ['nodes', 'connections', '_nodes', '_connections'])
          )
          checkNodes({
            nodes: workflow.nodes,
            variables: workflow.variables,
            timerOnNodes: workflow.timerOnNodes
          })
          checkConnections({
            nodes: workflow.nodes,
            connections: workflow.connections,
            variables: workflow.variables
          })
          $scope.messageExists =
            $scope.validatorData.error.length ||
            $scope.validatorData.warning.length ||
            $scope.validatorData.information.length
          $scope.processValidatorReflection.errorExists =
            $scope.validatorData.error.length > 0
          $scope.processValidatorReflection.getErrors = () =>
            $scope.validatorData.error
        } catch (ex) {
          console.error(ex)
        }
      }

      /**
       * increase state counter by one and add message to messages array
       * @param {string} state error, warning or information
       * @param {string} messageText A message to be displayed to the user
       * @param {boolean} useHandler if use handler when click on message
       * @param {object} messageAdditionalData data that transferred to handler
       */
      const insertMessages = function insertMessages (
        state,
        messageText,
        useHandler = false,
        messageAdditionalData = null
      ) {
        $scope.iconData[state].number++
        const msg = {
          text: messageText,
          handler: useHandler ? $scope.validatorHandler : null,
          data: messageAdditionalData,
          state
        }
        $scope.allMessages.push(msg)
        $scope.validatorData[state].push(msg)
      }

      /**
       * Reset all counters and message board
       */
      const resetErrors = function resetErrors () {
        $scope.messageExists = false
        $scope.allMessages = []
        $scope.states.forEach(state => {
          $scope.iconData[state].number = 0
          $scope.validatorData[state] = []
        })
      }
      /**
       * Check for following errors:
       * nameNotDefined
       * Check for following warnings:
       * -
       * Check for following information:
       * -
       * @param {object} workflow
       */
      const checkWorkflow = function checkWorkflow (workflow) {
        if (isNilOrEmpty(workflow.name)) {
          insertMessages(
            STATES.ERROR,
            $translate.instant('WF.ERRORS.NO_WORKFLOW_NAME')
          )
        }
        if (
          !workflow.timerOnNodes &&
          (_.isNil(workflow.workflowTimer) || workflow.workflowTimer === 0)
        ) {
          insertMessages(
            STATES.INFORMATION,
            $translate.instant('WF.ERRORS.NO_WORKFLOW_STANDARD_TIME')
          )
        }
        if (Array.isArray(workflow.parts) && workflow.parts.length > 0) {
          workflow.parts.forEach(part => {
            if (isNilOrEmpty(part.sku)) {
              insertMessages(
                STATES.ERROR,
                $translate.instant('WF.ERRORS.WORKFLOW_NO_PART_SKU')
              )
            } else if (
              workflowPartsByNumber[part.sku]?.managedByRevision &&
              !part.rev
            ) {
              insertMessages(
                STATES.ERROR,
                $translate.instant('WF.ERRORS.WORKFLOW_PART_NO_REV', {
                  sku: part.sku
                })
              )
            }
          })
          // Check if there is duplicate sku & rev in parts
          const duplicates = workflow.parts.reduce((acc, part) => {
            const key = `${part.sku}____REV____${part.rev}`
            if (!acc[key]) {
              acc[key] = { count: 0, sku: part.sku, rev: part.rev }
            }
            acc[key].count++
            return acc
          }, {})

          const duplicatePart = Object.values(duplicates).find(d => d.count > 1)
          if (duplicatePart) {
            insertMessages(
              STATES.ERROR,
              $translate.instant('WF.ERRORS.WORKFLOW_DUPLICATE_SKU', {
                sku: duplicatePart.sku,
                rev: duplicatePart.rev
              })
            )
          }
        }
        if (
          $scope.workflowTypes
            .filter(wType => workflow.workflowTypeIds.includes(wType.id))
            .some(wType => wType.createStock) &&
          (!Array.isArray(workflow.parts) ||
            workflow.parts.filter(part => part.sku).length === 0)
        ) {
          insertMessages(
            STATES.ERROR,
            $translate.instant(
              'WF.ERRORS.WORKFLOW_MANAGED_AS_STOCK_WITHOUT_PRODUCT'
            ),
            false,
            null
          )
        }
        if (Array.isArray(workflow.listItems) && workflow.listItems.length) {
          for (let l = 0; l < workflow.listItems.length; l++) {
            const listItem = workflow.listItems[l]
            if (!_.isNil(listItem.deletedAt)) {
              insertMessages(
                STATES.ERROR,
                $translate.instant(
                  'WF.ERRORS.DELETED_LIST_ITEM',
                  {
                    listItemName: listItem.name
                  },
                  null,
                  null,
                  'sceParameters'
                )
              )
            }
          }
        }
        if (isNilOrEmpty(workflow.workflowTypeIds)) {
          insertMessages(
            STATES.ERROR,
            $translate.instant('WF.ERRORS.NO_WORKFLOW_TYPE')
          )
        } else {
          const deletedWorkflowTypes = $scope.workflowTypes.filter(
            wType =>
              workflow.workflowTypeIds.includes(wType.id) && wType.deletedAt
          )
          if (deletedWorkflowTypes.length > 0) {
            const workflowTypeName = deletedWorkflowTypes
              .map(wType => {
                return wType.name
              })
              .join(', ')
            insertMessages(
              STATES.ERROR,
              $translate.instant('WF.ERRORS.DELETED_WORKFLOW_TYPE', {
                workflowTypeName
              })
            )
          }
        }
      }

      const checkNodeListItems = function checkNodeListItems (node) {
        if (Array.isArray(node.listItems) && node.listItems.length > 0) {
          for (let l = 0; l < node.listItems.length; l++) {
            const listItem = node.listItems[l]
            if (!_.isNil(listItem.deletedAt)) {
              insertMessages(
                STATES.ERROR,
                $translate.instant(
                  'WF.ERRORS.DELETED_LIST_ITEM_IN_NODE',
                  {
                    nodeName: node.name,
                    listItemName: listItem.name
                  },
                  null,
                  null,
                  'sceParameters'
                ),
                true,
                { id: node.id, action: 'select', type: 'node' }
              )
            }
          }
        }
      }

      const checkNodeSMGResource = function checkNodeSMGResource (node) {
        if (
          !isNilOrEmpty(node.resource) &&
          !isNilOrEmpty(node.resource.ext) &&
          node.resource.ext === 'smg' &&
          (!node.data || isNilOrEmpty(node.data.view))
        ) {
          insertMessages(
            STATES.ERROR,
            $translate.instant('WF.ERRORS.SMG_RESOURCE_WITHOUT_VIEW', {
              nodeName: node.name
            }),
            true,
            { id: node.id, action: 'select', type: 'node' }
          )
        }
      }

      const checkNodeTools = function checkNodes (node) {
        if (Array.isArray(node.toolIds) && node.toolIds.length > 0) {
          for (let t = 0; t < node.toolIds.length; t++) {
            const toolId = node.toolIds[t]
            const toolObject = toolsById[toolId]
            if (isNilOrEmpty(toolObject) || toolObject.deletedAt !== null) {
              const toolName = toolObject
                ? toolObject.name
                : $translate.instant('WF.ERRORS.DELETED_TOOL_CONSTANT')
              insertMessages(
                STATES.WARNING,
                $translate.instant('WF.ERRORS.DELETED_TOOL', {
                  nodeName: node.name,
                  toolName
                }),
                true,
                { id: node.id, action: 'select', type: 'node' }
              )
            }
          }
        }
      }

      const checkReferenceNode = function checkReferenceNode (node) {
        if (node.isReference === true) {
          if (isNilOrEmpty(node.recordId)) {
            insertMessages(
              STATES.ERROR,
              $translate.instant('WF.ERRORS.NODE_REFERENCE_NOT_ASSOCIATE'),
              true,
              { id: node.id, action: 'select', type: 'node' }
            )
          } else if (!workflowByRecordId[node.recordId]) {
            insertMessages(
              STATES.ERROR,
              $translate.instant('WF.ERRORS.NODE_REFERENCE_DELETED_WORKFLOW', {
                workflowName: node.name
              }),
              true,
              { id: node.id, action: 'select', type: 'node' }
            )
          }
        }
      }

      const checkNodeSigns = function checkNodeSigns (node) {
        if (
          node.signsSettings &&
          Array.isArray(node.signsSettings.signs) &&
          node.signsSettings.signs.length > 0
        ) {
          for (let s = 0; s < node.signsSettings.signs.length; s++) {
            const sign = node.signsSettings.signs[s]
            if (
              Array.isArray(sign.certificateIds) &&
              sign.certificateIds.length > 0
            ) {
              for (let c = 0; c < sign.certificateIds.length; c++) {
                const certificateId = sign.certificateIds[c]
                const certificate = certificatesById[certificateId]
                if (certificate.deletedAt) {
                  insertMessages(
                    STATES.WARNING,
                    $translate.instant('WF.ERRORS.DELETED_CERTIFICATE', {
                      nodeName: node.name,
                      certificateName: certificate.name
                    }),
                    true,
                    { id: node.id, action: 'select', type: 'node' }
                  )
                }
              }
            }
          }
        }
      }

      const checkNodeTimer = function checkNodeTimer (
        node,
        timerOnNodes = false
      ) {
        if (
          timerOnNodes &&
          !node.isReference &&
          (_.isNil(node.nodeTimer) || node.nodeTimer === 0)
        ) {
          insertMessages(
            STATES.WARNING,
            $translate.instant('WF.ERRORS.NO_STANDARD_TIME_IN_NODE', {
              nodeName: node.name
            }),
            true,
            { id: node.id, action: 'select', type: 'node' }
          )
        }
      }

      const checkFieldExistence = function checkFieldExistence (
        node,
        potentialField,
        nodes = [],
        messages = {},
        action = null
      ) {
        if (
          typeof potentialField === 'string' &&
          potentialField.includes('field_')
        ) {
          const [, nodeId, fieldId] = potentialField.split('_')
          const searchNode = nodes.find(n => n.id === nodeId)
          const field = fieldsById[fieldId]
          const translateParams = messages.fieldParams || {}
          if (_.isNil(searchNode)) {
            insertMessages(
              STATES.ERROR,
              $translate.instant(
                messages.node,
                {
                  ...translateParams,
                  nodeName: node.name
                },
                null,
                null,
                'sceParameters'
              ),
              true,
              action || { id: node.id, action: 'select', type: 'node' }
            )
          } else if (searchNode.fieldIds.indexOf(fieldId) === -1) {
            const nodeName = node && node.name ? node.name : searchNode.name
            insertMessages(
              STATES.ERROR,
              $translate.instant(
                messages.field,
                {
                  ...translateParams,
                  fieldName: field.title,
                  nodeName
                },
                null,
                null,
                'sceParameters'
              ),
              true,
              action || { id: node.id, action: 'select', type: 'node' }
            )
          }
        }
      }

      const checkVariableExistence = function checkVariableExistence (
        node,
        potentialVariable,
        variables = [],
        message = '',
        translateParams = {},
        action = null
      ) {
        if (
          typeof potentialVariable === 'string' &&
          potentialVariable.includes('variable_')
        ) {
          const [, ...splittedVariableName] = potentialVariable.split('_')
          const variableName = splittedVariableName.join('_')
          if (!variables.find(v => v.name === variableName)) {
            insertMessages(
              STATES.ERROR,
              $translate.instant(
                message,
                {
                  ...translateParams,
                  variableName,
                  nodeName: node.name
                },
                null,
                null,
                'sceParameters'
              ),
              true,
              action || { id: node.id, action: 'select', type: 'node' }
            )
          }
        }
      }

      const checkColumnExistence = function checkColumnExistence (
        node,
        tableId,
        columnId,
        message = '',
        translateParams = {}
      ) {
        const table = $scope.tableById[tableId]
        const columnDefinition = table.columns.find(c => c.id === columnId)
        if (!columnDefinition || columnDefinition.deletedAt) {
          insertMessages(
            STATES.ERROR,
            $translate.instant(
              message,
              {
                ...translateParams,
                tableName: table.name,
                columnName: columnDefinition
                  ? columnDefinition.name
                  : 'Deleted Column'
              },
              null,
              null,
              'sceParameters'
            ),
            true,
            { id: node.id, action: 'select', type: 'node' }
          )
        }
      }

      const checkNodeDisplayExpression = function checkNodeDisplayExpression (
        node,
        nodes,
        variables = []
      ) {
        const displayExpression =
          Array.isArray(node.expressions) &&
          node.expressions.find(exp => exp.type === 'display')
        if (
          displayExpression &&
          !isNilOrEmpty(displayExpression.conditions) &&
          Array.isArray(displayExpression.conditions.rules) &&
          displayExpression.conditions.rules.length > 0
        ) {
          for (let r = 0; r < displayExpression.conditions.rules.length; r++) {
            const { field, value } = displayExpression.conditions.rules[r]
            checkFieldExistence(node, field, nodes, {
              field: 'WF.ERRORS.UNRECOGNIZED_FIELD_USED_IN_DISPLAY_EXPRESSION',
              node: 'WF.ERRORS.UNRECOGNIZED_FIELD_USED_IN_DISPLAY_EXPRESSION'
            })
            checkFieldExistence(node, value, nodes, {
              field: 'WF.ERRORS.UNRECOGNIZED_FIELD_USED_IN_DISPLAY_EXPRESSION',
              node: 'WF.ERRORS.UNRECOGNIZED_FIELD_USED_IN_DISPLAY_EXPRESSION'
            })
            checkVariableExistence(
              node,
              field,
              variables,
              'WF.ERRORS.UNRECOGNIZED_VARIABLE_USED_IN_DISPLAY_EXPRESSION'
            )
            checkVariableExistence(
              node,
              value,
              variables,
              'WF.ERRORS.UNRECOGNIZED_VARIABLE_USED_IN_DISPLAY_EXPRESSION'
            )
          }
        }
      }

      const checkNodeTriggers = function checkNodeTriggers (
        node,
        nodes,
        variables = []
      ) {
        const formulaCheck = function formulaCheck (triggerName, formula) {
          let match
          // eslint-disable-next-line
          const regexCustomField = /customFields\["(.*?)\"]/gm
          // eslint-disable-next-line
          const regexVariable = /variables\["(.*?)\"]/gm

          const formulaCustomFieldIds = new Set()
          const formulaVariableNames = new Set()

          while ((match = regexCustomField.exec(formula))) {
            const field = match[1]
            formulaCustomFieldIds.add(field)
          }

          while ((match = regexVariable.exec(formula))) {
            const variable = match[1]
            formulaVariableNames.add(variable)
          }
          const formulaCustomFields = [...formulaCustomFieldIds]
          const formulaVariables = [...formulaVariableNames]
          formulaCustomFields.forEach(field => {
            const [nId, fieldId] = field.split('_')
            const nodeObj = nodes.find(node => node.id === nId)
            if (!nodeObj) {
              insertMessages(
                STATES.ERROR,
                $translate.instant(
                  'WF.ERRORS.DELETED_NODE_ACTION_USED_IN_TRIGGER',
                  {
                    triggerName
                  },
                  null,
                  null,
                  'sceParameters'
                ),
                true,
                { id: node.id, action: 'select', type: 'node' }
              )
            } else {
              const fieldExists =
                Array.isArray(nodeObj.fieldIds) &&
                nodeObj.fieldIds.indexOf(fieldId) > -1
              const fieldObj = fieldsById[fieldId]
              if (!fieldExists) {
                insertMessages(
                  STATES.ERROR,
                  $translate.instant(
                    'WF.ERRORS.DELETED_FIELD_USED_IN_TRIGGER',
                    {
                      triggerName,
                      fieldName: fieldObj.title,
                      nodeName: node.name
                    },
                    null,
                    null,
                    'sceParameters'
                  ),
                  true,
                  { id: node.id, action: 'select', type: 'node' }
                )
              }
            }
          })
          formulaVariables.forEach(variable => {
            const variableExists = variables.find(v => v.name === variable)
            if (!variableExists) {
              insertMessages(
                STATES.ERROR,
                $translate.instant(
                  'WF.ERRORS.DELETED_VARIABLE_USED_IN_TRIGGER',
                  {
                    triggerName,
                    variableName: variable
                  },
                  null,
                  null,
                  'sceParameters'
                ),
                true,
                { id: node.id, action: 'select', type: 'node' }
              )
            }
          })
        }
        const checkTableValue = function checkTableValue (triggerName, action) {
          const columnIds = Object.keys(action.value)
          for (let i = 0; i < columnIds.length; i++) {
            const columnId = columnIds[i]
            const formula = _.clone(action.value[columnId])
            formulaCheck(triggerName, formula)
          }
        }
        const checkTableWhereCondition = function checkTableWhereCondition (
          node,
          triggerName,
          action
        ) {
          const { principleId, whereCondition } = action
          if (
            Array.isArray(whereCondition.rules) &&
            whereCondition.rules.length > 0
          ) {
            for (let r = 0; r < whereCondition.rules.length; r++) {
              const { field, value } = whereCondition.rules[r]
              checkColumnExistence(
                node,
                principleId,
                field,
                'WF.ERRORS.DELETED_COLUMN_USED_IN_TABLE_OPERATION',
                { triggerName }
              )
              const table = $scope.tableById[action.principleId]
              checkFieldExistence(node, value, nodes, {
                field: 'WF.ERRORS.UNRECOGNIZED_FIELD_USED_IN_TABLE_OPERATION',
                fieldParams: { triggerName, tableName: table.name },
                node: 'WF.ERRORS.UNRECOGNIZED_FIELD_USED_IN_TABLE_OPERATION'
              })
              checkVariableExistence(
                node,
                value,
                variables,
                'WF.ERRORS.UNRECOGNIZED_VARIABLE_USED_IN_TABLE_OPERATION',
                { triggerName, tableName: table.name }
              )
            }
          }
        }
        if (Array.isArray(node.triggers) && node.triggers.length > 0) {
          for (let t = 0; t < node.triggers.length; t++) {
            const trigger = node.triggers[t]
            const triggerName = trigger.name
            if (
              Array.isArray(trigger.conditions) &&
              trigger.conditions.length > 0
            ) {
              for (let c = 0; c < trigger.conditions.length; c++) {
                const condition = trigger.conditions[c]
                if (
                  Array.isArray(condition.values) &&
                  condition.values.length > 0
                ) {
                  for (let v = 0; v < condition.values.length; v++) {
                    const conditionValue = condition.values[v]
                    checkFieldExistence(node, conditionValue.field, nodes, {
                      field: 'WF.ERRORS.DELETED_FIELD_USED_IN_TRIGGER',
                      fieldParams: {
                        triggerName
                      },
                      node: 'WF.ERRORS.DELETED_NODE_USED_IN_TRIGGER'
                    })
                    checkFieldExistence(node, conditionValue.value, nodes, {
                      field: 'WF.ERRORS.DELETED_FIELD_USED_IN_TRIGGER',
                      fieldParams: {
                        triggerName
                      },
                      node: 'WF.ERRORS.DELETED_NODE_USED_IN_TRIGGER'
                    })
                    checkVariableExistence(
                      node,
                      conditionValue.field,
                      variables,
                      'WF.ERRORS.DELETED_VARIABLE_USED_IN_TRIGGER',
                      { triggerName }
                    )
                    checkVariableExistence(
                      node,
                      conditionValue.value,
                      variables,
                      'WF.ERRORS.DELETED_VARIABLE_USED_IN_TRIGGER',
                      { triggerName }
                    )
                  }
                }
                if (
                  Array.isArray(condition.actions) &&
                  condition.actions.length > 0
                ) {
                  for (let a = 0; a < condition.actions.length; a++) {
                    const action = condition.actions[a]
                    if (
                      [
                        TRIGGER_TYPES.SET_VARIABLES,
                        TRIGGER_TYPES.SET_VARIABLES_WITH_FORMULA,
                        TRIGGER_TYPES.INCREASE_VARIABLE,
                        TRIGGER_TYPES.DECREASE_VARIABLE
                      ].includes(action.principleType)
                    ) {
                      const isExists = variables.find(
                        variable => variable.name === action.principleId
                      )
                      if (!isExists) {
                        insertMessages(
                          STATES.ERROR,
                          $translate.instant(
                            'WF.ERRORS.DELETED_VARIABLE_USED_IN_TRIGGER',
                            {
                              triggerName,
                              variableName: action.principleId
                            },
                            null,
                            null,
                            'sceParameters'
                          ),
                          true,
                          { id: node.id, action: 'select', type: 'node' }
                        )
                      } else if (
                        action.principleType !==
                          TRIGGER_TYPES.SET_VARIABLES_WITH_FORMULA &&
                        action.principleType !== TRIGGER_TYPES.SET_VARIABLES &&
                        isExists.type === 'text'
                      ) {
                        insertMessages(
                          STATES.ERROR,
                          $translate.instant(
                            'WF.ERRORS.TEXT_VARIABLE_USED_IN_FORMULA_TRIGGER',
                            {
                              triggerName,
                              variableName: action.principleId
                            },
                            null,
                            null,
                            'sceParameters'
                          ),
                          true,
                          { id: node.id, action: 'select', type: 'node' }
                        )
                      } else if (
                        action.principleType ===
                          TRIGGER_TYPES.SET_VARIABLES_WITH_FORMULA &&
                        !_.isNil(action.value)
                      ) {
                        if (isExists.type === 'text') {
                          insertMessages(
                            STATES.INFORMATION,
                            $translate.instant(
                              'WF.ERRORS.TEXT_VARIABLE_USED_IN_FORMULA_TRIGGER',
                              {
                                triggerName,
                                variableName: action.principleId
                              },
                              null,
                              null,
                              'sceParameters'
                            ),
                            true,
                            { id: node.id, action: 'select', type: 'node' }
                          )
                        }
                        formulaCheck(triggerName, action.value)
                      }
                    } else if (
                      action.principleType === TRIGGER_TYPES.SEND_SMS &&
                      !$rootScope.appSettings.enableSMS
                    ) {
                      insertMessages(
                        STATES.ERROR,
                        $translate.instant(
                          'WF.ERRORS.SMS_USED_IN_TRIGGER',
                          {
                            triggerName
                          },
                          null,
                          null,
                          'sceParameters'
                        ),
                        true,
                        { id: node.id, action: 'select', type: 'node' }
                      )
                    } else if (
                      action.principleType === TRIGGER_TYPES.CREATE_WORKORDER
                    ) {
                      if (!workorderTemplatesById[action.principleId]) {
                        insertMessages(
                          STATES.ERROR,
                          $translate.instant(
                            'WF.ERRORS.DELETED_WORKORDER_TEMPLATE_USED_IN_TRIGGER',
                            {
                              triggerName
                            },
                            null,
                            null,
                            'sceParameters'
                          ),
                          true,
                          { id: node.id, action: 'select', type: 'node' }
                        )
                      }
                    } else if (
                      action.principleType === TRIGGER_TYPES.PICK_TO_LIGHT ||
                      action.principleType === TRIGGER_TYPES.GPIO
                    ) {
                      if (
                        action.principleType === TRIGGER_TYPES.PICK_TO_LIGHT
                      ) {
                        const deviceFamily = $scope.deviceFamilies.find(
                          dv => dv.id === action.value.deviceFamily
                        )
                        if (!deviceFamily) {
                          insertMessages(
                            STATES.ERROR,
                            $translate.instant(
                              'WF.ERRORS.DELETED_DEVICE_FAMILY_USED_IN_TRIGGER',
                              {
                                triggerName
                              },
                              null,
                              null,
                              'sceParameters'
                            ),
                            true,
                            { id: node.id, action: 'select', type: 'node' }
                          )
                        }
                      }
                      const station = $scope.stationsById[action.principleId]
                      if (!station && action.principleId !== 'local_station') {
                        insertMessages(
                          STATES.ERROR,
                          $translate.instant(
                            'WF.ERRORS.DELETED_STATION_USED_IN_TRIGGER',
                            {
                              triggerName
                            },
                            null,
                            null,
                            'sceParameters'
                          ),
                          true,
                          { id: node.id, action: 'select', type: 'node' }
                        )
                      }
                    } else if (
                      action.principleType === TRIGGER_TYPES.TABLE_OPERATIONS
                    ) {
                      const table = $scope.tableById[action.principleId]
                      if (!table || table.deletedAt !== null) {
                        insertMessages(
                          STATES.ERROR,
                          $translate.instant(
                            'WF.ERRORS.DELETED_TABLE_USED_IN_TRIGGER',
                            {
                              triggerName
                            },
                            null,
                            null,
                            'sceParameters'
                          ),
                          true,
                          { id: node.id, action: 'select', type: 'node' }
                        )
                      }
                      switch (action.principleOp) {
                        case NPT_OPERATIONS.INSERT:
                          checkTableValue(triggerName, action)
                          break
                        case NPT_OPERATIONS.UPDATE:
                        case NPT_OPERATIONS.UPSERT:
                          checkTableValue(triggerName, action)
                          checkTableWhereCondition(node, triggerName, action)
                          break
                        case NPT_OPERATIONS.INCREASE:
                        case NPT_OPERATIONS.DECREASE:
                          formulaCheck(triggerName, action.value)
                          break
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      /**
       * Check for following errors:
       * rootNotFound, triggerNoValid
       * Check for following warnings:
       * deletedTool, deletedCertificate
       * Check for following information:
       * -
       * @param {object} workflow
       */
      const checkNodes = function checkNodes (workflow) {
        const { nodes } = workflow
        const workflowVariable = workflow.variables
        const variables = [...systemVariables, ...workflowVariable]
        if (nodes) {
          const root = nodes.find(node => node.is_root)
          if (!root) {
            insertMessages(
              STATES.ERROR,
              $translate.instant('WF.ERRORS.NO_ROOT_FOUND')
            )
          }
          const uniqueSMGIds = new Set(
            nodes
              .filter(
                node =>
                  !isNilOrEmpty(node.resource) && node.resource.ext === 'smg'
              )
              .map(node => node.ResourceId)
          )
          if (uniqueSMGIds.size > 1) {
            insertMessages(
              STATES.WARNING,
              $translate.instant('WF.ERRORS.MORE_THAN_ONE_SMG_FILE')
            )
          }
          for (let n = 0; n < nodes.length; n++) {
            const node = nodes[n]
            checkNodeSMGResource(node)
            checkNodeListItems(node)
            checkNodeTools(node)
            checkReferenceNode(node)
            checkNodeSigns(node)
            checkNodeTimer(node, workflow.timerOnNodes)
            checkNodeDisplayExpression(node, nodes, variables)
            checkNodeTriggers(node, nodes, variables)
          }
        }
      }
      /**
       * Check for following errors:
       * conditionNoValid, connectionWithOutCondition
       * Check for following warnings:
       * noLinkedNode, rootNotConnected
       * Check for following information:
       * -
       * @param {object} workflow
       */
      const checkConnections = function checkConnections (workflow) {
        const { nodes, connections } = workflow
        let { variables } = workflow
        variables = [...systemVariables, ...variables]
        let nodeLinked = {}
        if (nodes) {
          nodeLinked = nodes.reduce(
            (obj, item) => ((obj[item.id] = false), obj), // eslint-disable-line
            {}
          )
          const root = nodes.find(node => node.is_root)
          if (root) {
            nodeLinked[root.id] = true
            if (connections) {
              const someRootConnection = connections.find(
                c => c.fromId === root.id
              )
              if (!someRootConnection && nodes.length > 1) {
                insertMessages(
                  STATES.WARNING,
                  $translate.instant('WF.ERRORS.NO_LINKED_ROOT', {
                    nodeName: root.name
                  }),
                  true,
                  { id: root.id, action: 'select', type: 'node' }
                )
              }
            }
          }
        }
        if (connections) {
          const moreThenOneConnection =
            _.map(
              _.difference(
                connections,
                _.uniqBy(connections, 'fromId'),
                'fromId'
              ),
              'fromId'
            ) || []
          for (let c = 0; c < connections.length; c++) {
            const connection = connections[c]
            nodeLinked[connection.toId] = true
            if (
              connection.conditions &&
              Array.isArray(connection.conditions.rules) &&
              connection.conditions.rules.length > 0
            ) {
              for (let r = 0; r < connection.conditions.rules.length; r++) {
                const rule = connection.conditions.rules[r]
                checkFieldExistence(
                  {},
                  rule.field,
                  nodes,
                  {
                    field: 'WF.ERRORS.DELETED_FIELD_USED_IN_CONNECTION',
                    fieldParams: { connectionName: connection.name },
                    node: 'WF.ERRORS.DELETED_NODE_USED_IN_CONNECTION'
                  },
                  {
                    id: connection.id,
                    action: 'select',
                    type: 'connection'
                  }
                )
                checkFieldExistence(
                  {},
                  rule.value,
                  nodes,
                  {
                    field: 'WF.ERRORS.DELETED_FIELD_USED_IN_CONNECTION',
                    fieldParams: { connectionName: connection.name },
                    node: 'WF.ERRORS.DELETED_NODE_USED_IN_CONNECTION'
                  },
                  {
                    id: connection.id,
                    action: 'select',
                    type: 'connection'
                  }
                )
                checkVariableExistence(
                  {},
                  rule.field,
                  variables,
                  'WF.ERRORS.DELETED_VARIABLE_USED_IN_CONNECTION',
                  {
                    id: connection.id,
                    action: 'select',
                    type: 'connection',
                    connectionName: connection.name
                  }
                )
                checkVariableExistence(
                  {},
                  rule.value,
                  variables,
                  'WF.ERRORS.DELETED_VARIABLE_USED_IN_CONNECTION',
                  {
                    id: connection.id,
                    action: 'select',
                    type: 'connection',
                    connectionName: connection.name
                  }
                )
              }
            } else if (moreThenOneConnection.indexOf(connection.fromId) > -1) {
              if (
                connection.fromId &&
                connection.toId &&
                nodes.find(node => node.id === connection.fromId) &&
                nodes.find(node => node.id === connection.toId)
              ) {
                insertMessages(
                  STATES.ERROR,
                  $translate.instant('WF.ERRORS.CONNECTION_WITHOUT_CONDITION', {
                    connectionName: connection.name
                  }),
                  true,
                  {
                    id: connection.id,
                    action: 'select',
                    type: 'connection'
                  }
                )
              }
            }
          }
        }
        const notLinkedNodesIds = Object.keys(nodeLinked).filter(
          nodeId => nodeLinked[nodeId] === false
        )
        for (let i = 0; i < notLinkedNodesIds.length; i++) {
          const nodeId = notLinkedNodesIds[i]
          const nodeName = nodes.find(n => n.id === nodeId).name
          insertMessages(
            STATES.WARNING,
            $translate.instant(
              'WF.ERRORS.NO_LINKED_NODE',
              {
                nodeName
              },
              null,
              null,
              'sceParameters'
            ),
            true,
            { id: nodeId, action: 'select', type: 'node' }
          )
        }
      }

      $scope.update = $scope.updateValidator
      $scope.filterProcessValidator = state => {
        $scope.validatorFilter[state] = !$scope.validatorFilter[state]
      }
    }
  }
}

module.exports = processValidator
