/* global flatpickr */

/** @ngInject */
function DatePicker () {
  require('./date-picker.scss')
  return {
    template: require('./date-picker.html'),
    bindings: {
      ngModel: '<',
      fpOpts: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function DatePicker (
        $scope,
        $element,
        $rootScope,
        $translate,
        DateTimeFormatService
      ) {
        const dateTimeFormats =
          DateTimeFormatService.getDateTimeFormatsForField()
        let fp
        $scope.openPicker = function () {
          fp.open()
        }
        $scope.clearPicker = function () {
          fp.clear(true)
          fp.close()
          fp.setDate(null)
          $scope.ngModel = undefined
        }

        this.$onChanges = function (changeObj) {
          // Ignore if first change
          if (changeObj.ngModel.isFirstChange()) return

          // On change of ngModel, update the flatpickr instance
          if (changeObj.ngModel && changeObj.ngModel.currentValue) {
            $scope.ngModel = changeObj.ngModel.currentValue
            fp.setDate(changeObj.ngModel.currentValue)
          }
          // On change of fpOpts, destroy the flatpickr instance and create a new one
          if (changeObj.fpOpts && fp) {
            fp.destroy()
            fp = $($element[0]).flatpickr(changeObj.fpOpts.currentValue)
          }
        }

        this.$onInit = async function () {
          const lang = $translate.use()
          let localization = null
          try {
            if (lang !== 'en') {
              const res = await import(
                /* webpackChunkName: "flatpickr-[request]" */ 'root/node_modules/flatpickr/dist/l10n/' +
                  lang +
                  '.js'
              )
              if (res) {
                localization = res.default[lang]
                // flatpickr.localize()
              }
            }
          } catch (ex) {
            console.warn(
              `Fail to load user language ${lang}, fallback to en`,
              ex
            )
          }

          $scope.ngModel = this.ngModel
          if (localization) this.fpOpts.locale = localization
          this.fpOpts.wrap = true
          this.fpOpts.weekNumbers = true
          this.fpOpts.defaultDate = this.ngModel
          this.fpOpts.dateFormat = dateTimeFormats.date
          $scope.fpOpts = this.fpOpts
          if (!flatpickr) {
            console.error('Unable to find any flatpickr installation')
            return
          }
          fp = $($element[0]).flatpickr(this.fpOpts)
          fp.config.onChange.push(function (selectedDates, dateStr, instance) {
            $scope.ngModel = selectedDates
          })
        }
        // destroy the flatpickr instance when the dom element is removed
        this.$onDestroy = function () {
          fp.destroy()
        }
      }
  }
}

module.exports = DatePicker
