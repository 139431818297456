/* global */
require('./datePicker.scss')
/** @ngInject */
function datePickerController ($scope, $window, DateTimeFormatService) {
  $scope.hasTime = $scope.options.type === 'dateTimePicker'
  const dateTimeFormats = DateTimeFormatService.getDateTimeFormatsForField()
  $scope.dateOpts = {
    enableTime: $scope.hasTime,
    time_24hr: true,
    placeholder: $scope.to.placeholder || null,
    dateFormat: $scope.hasTime
      ? `${dateTimeFormats.date} ${dateTimeFormats.time}`
      : dateTimeFormats.date,
    minuteIncrement: 1,
    time_24hr: dateTimeFormats.time?.endsWith('K') ? false : true,
    minDate: $scope.to.minDate || null,
    maxDate: $scope.to.maxDate || null,
    defaultDate: $scope.model[$scope.options.key] || new Date()
  }
}

module.exports = datePickerController
